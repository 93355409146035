<template>
  <v-app style="background-color: whitesmoke;overflow: hidden;">
    <v-main>
      <router-view></router-view>
      <snackbar :snackbar="getSnackbarStatus"></snackbar>
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from "@/components/general/Snackbar.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { Snackbar },
  name: "App",
  data: () => ({}),
  beforeCreate() {},
  computed: {
    ...mapGetters(["getSnackbarStatus"]),
  },
};
</script>
