import { SET_SERVICES, RESET_SERVICES } from "@/store/actions/task";

export const getDefaultState = () => ({
  services: [],
  loading: null,
  servicingPartner: [],
  from: "",
});
const state = getDefaultState();

const getters = {
  getServiceState: (state) => state,
  getServices: (state) => state.services,
  getServicingPartner: (state) => state.servicingPartner,
};

const actions = {};

const mutations = {
  [SET_SERVICES]: (state, value) => {
    state.services = value.services;
    state.from = value.from;
    state.servicingPartner = value.services.map((element) => element.crm_boat);
  },
  [RESET_SERVICES]: (state, value) => {
    getDefaultState();
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
