import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#1169EE",
        secondary: "#0D4FB2",
        accent: "#8c9eff",
        success: "#03D06E",
        info: "#1169EE",
        warning: "#EE6011",
        error: "#D70000",
      },
    },
  },
});
