import store from "@/store/index";
var error = (text = "An error has occurred", scroll = true) => {
  store.commit("SNACKBAR", {
    active: true,
    text: text,
    color: "red",
  });
  scroll && window.scrollTo(0, 0);
};
var success = (text, scroll = true) => {
  store.commit("SNACKBAR", {
    active: true,
    text: text,
    color: store.getters.getTheme.light.primary,
  });
  scroll && window.scrollTo(0, 0);
};
var info = (text) => {
  store.commit("SNACKBAR", {
    active: true,
    text: text,
    color: "blue",
  });
  window.scrollTo(0, 0);
};
export default { error, success, info };
