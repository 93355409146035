<template>
  <v-snackbar
    v-model="snackbar.active"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
    :right="true"
    :bottom="true"
  >
    {{ snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        dark
        class="float-right"
        v-bind="attrs"
        text
        @click="snackbar.active = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: ["snackbar"],
  data: () => ({}),
  computed: {
    multiline() {
      return this.snackbar.multiLine ? this.snackbar.multiLine : false;
    },
    timeout() {
      return this.snackbar.timeout ? this.snackbar.timeout : 4000;
    },
  },
};
</script>

<style></style>
