import { SNACKBAR } from "@/store/actions/snackbar";

const state = {
  snackbar: {
    active: false,
    text: null,
    timeout: 4000,
    color: "blue",
  },
};

const getters = {
  getSnackbarStatus: (state) => state.snackbar,
};

const actions = {};

const mutations = {
  [SNACKBAR]: (state, value) => {
    state.snackbar = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
