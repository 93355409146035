import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMoment from "vue-moment";
import VueSanitize from "vue-sanitize";
import axios from "axios";
import { USER_SET_ACCESS_TOKEN } from "@/store/actions/user";

Vue.use(VueSanitize);
Vue.use(VueMoment);

Vue.config.performance = true;
Vue.config.productionTip = false;

window.addEventListener(
  "message",
  (event) => {
    if (
      [
        "https://web.sentinelmarine.net",
        "https://web.seanapps.app",
        "https://stage-web.sentinelmarine.net",
        "https://stage-web.seanapps.app",
        "http://localhost:8000",
        "http://app.local:8000",
      ].indexOf(event.origin) === -1
    ) {
      return;
    }
    axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${event.data.accessToken}`;
    store.commit(USER_SET_ACCESS_TOKEN, { token: event.data.accessToken });
    store.commit("SET_THEME", { brand: event.data.brand });
    store.commit("SET_CLIENT_IDS", { brand: event.data.brand });
    store.commit("SET_LINKS", { brand: event.data.brand });
    store.commit("SET_LOGO", { brand: event.data.brand });

    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  },
  false
);
