import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import task from "./modules/task";
import permissions from "./modules/permissions";
import snackbar from "./modules/snackbar";
import theme from "./modules/theme";
import dashboards from "./modules/dashboards";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    snackbar,
    task,
    permissions,
    theme,
    dashboards,
  },
});
