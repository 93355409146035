import {
  SET_THEME,
  SET_LOGO,
  SET_CLIENT_IDS,
  SET_LINKS,
} from "@/store/actions/theme";
import themes from "@/utils/themes";
import Vuetify from "@/plugins/vuetify";

export const getDefaultState = () => ({
  theme: themes.defaultTheme,
  logo: themes.defaultLogo,
  clientIDs: themes.defaultClientIDs,
  links: themes.defaultLinks,
});
const state = getDefaultState();

const getters = {
  getTheme: (state) => state.theme,
  getLogo: (state) => state.logo,
  getClientIDs: (state) => state.clientIDs,
  getLinks: (state) => state.links,
};

const actions = {};

const mutations = {
  [SET_THEME]: (state, value) => {
    const setTheme = (event = {}) => {
      const theme =
        Object.keys(event).length !== 0 ? event : themes.defaultTheme;

      Vuetify.framework.theme.themes.dark = theme.dark;
      Vuetify.framework.theme.themes.light = theme.light;
      Vuetify.framework.theme.themes.name = theme.name;

      const root = document.documentElement;
      root.style.setProperty("--primary", theme.colorVariables.primary);
      root.style.setProperty("--secondary", theme.colorVariables.secondary);

      window.document.title = theme.title;
      state.theme = theme;
    };

    const themeExists = themes.theme.find((element) => {
      return element.id === value.brand;
    });

    typeof themeExists === "undefined" ? setTheme() : setTheme(themeExists);
  },
  [SET_LOGO]: (state, value) => {
    const setLogo = (event = {}) => {
      const logo = Object.keys(event).length !== 0 ? event : themes.defaultLogo;
      state.logo = logo;
    };

    const logoExists = themes.logo.find((element) => {
      return element.id === value.brand;
    });

    typeof logoExists === "undefined" ? setLogo() : setLogo(logoExists);
  },
  [SET_CLIENT_IDS]: (state, value) => {
    const setClientIDs = (event = {}) => {
      const clientIDs =
        Object.keys(event).length !== 0 ? event : themes.defaultClientIDs;
      state.clientIDs = clientIDs;
    };

    const clientIdExists = themes.clientIDs.find((element) => {
      return element.id === value.brand;
    });

    typeof clientIdExists === "undefined"
      ? setClientIDs()
      : setClientIDs(clientIdExists);
  },
  [SET_LINKS]: (state, value) => {
    const setLinks = (event = {}) => {
      const links =
        Object.keys(event).length !== 0 ? event : themes.defaultLinks;
      state.links = links;
    };

    const linkExists = themes.links.find((element) => {
      return element.id === value.brand;
    });

    typeof linkExists === "undefined" ? setLinks() : setLinks(linkExists);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
