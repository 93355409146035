const theme = [
  {
    name: "Sentinel",
    title: "Sentinel Partner Platform",
    path: "sentinel",
    id: "sentinel",
    dark: {
      primary: "#1169EE",
      secondary: "#0D4FB2",
      accent: "#8c9eff",
      success: "#03D06E",
      info: "#1169EE",
      warning: "#EE6011",
      error: "#D70000",
    },
    light: {
      primary: "#1169EE",
      secondary: "#0D4FB2",
      accent: "#8c9eff",
      success: "#03D06E",
      info: "#1169EE",
      warning: "#EE6011",
      error: "#D70000",
    },
    colorVariables: {
      primary: "#1169EE",
      secondary: "#0D4FB2",
    },
  },
  {
    name: "Beneteau",
    title: "SEANAPPS Partner Platform",
    path: "beneteau",
    id: "beneteau",
    dark: {
      primary: "#00006e",
      secondary: "#0087ff",
      accent: "#7CB342",
      success: "#4CAF50",
      info: "#6156d8",
      warning: "#1565C0",
      error: "#FF7043",
    },
    light: {
      primary: "#00006e",
      secondary: "#0087ff",
      accent: "#7CB342",
      success: "#4CAF50",
      info: "#6156d8",
      warning: "#1565C0",
      error: "#FF7043",
    },
    colorVariables: {
      primary: "#00006e",
      secondary: "#0087ff",
    },
  },
];

const logo = [
  {
    name: "Sentinel",
    path: "sentinel",
    id: "sentinel",
  },
  {
    name: "Beneteau",
    path: "beneteau",
    id: "beneteau",
  },
];

const clientIDs = [
  {
    hostnames: [],
    name: "Sentinel",
    id: "sentinel",
    clientId: "b2d1bc4b934efe0203cf",
    clientSecret: "315081eb0c13b39b9570427aafa7a414eaf68bb1",
  },
  {
    hostnames: ["localhost"],
    name: "Beneteau",
    id: "beneteau",
    clientId: "4f3ee8967d148c8b70e8",
    clientSecret: "65d913def85384a4fddc229acf57a37de2136518",
  },
];

const links = [
  {
    hostnames: [],
    name: "Sentinel",
    id: "sentinel",
    privacyPolicy: "https://www.sentinelmarine.net/mobile/privacy-policy",
    termsOfUse: "https://www.sentinelmarine.net/mobile/terms-of-use",
    termsAndConditions:
      "https://app.sentinelmarine.net/api/v2/documents/partner-tac/",
    legalNotice: false,
    supportEmail: "support@sentinelmarine.net",
  },
  {
    hostnames: ["localhost"],
    name: "Beneteau",
    id: "beneteau",
    privacyPolicy: "https://web-docs.seanapps.app/pp-dealers.html",
    termsOfUse: "https://web-docs.seanapps.app/tac-dealers.html",
    legalNotice: "https://www.seanapps.fr/en/legal-notice/",
    supportEmail: "contact@seanapps.fr",
  },
];

const defaultTheme = theme.find((element) => element.name === "Sentinel");
const defaultLogo = logo.find((element) => element.name === "Sentinel");
const defaultClientIDs = clientIDs.find(
  (element) => element.name === "Sentinel"
);
const defaultLinks = links.find((element) => element.name === "Sentinel");

export default {
  theme,
  defaultTheme,
  logo,
  defaultLogo,
  clientIDs,
  defaultClientIDs,
  links,
  defaultLinks,
};
