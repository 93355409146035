import axios from "axios";
import router from "@/router/index";
import store from "@/store";
import { USER_CRM_ERROR } from "@/store/actions/user";
import snackbar from "@/utils/snackbar";
import cookie from "./cookie";
import { USER_SET_ACCESS_TOKEN } from "@/store/actions/user";

axios.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling axios
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling axios
    if (error.response.status !== 401) {
      if (
        error.config.hasOwnProperty("errorHandle") &&
        error.config.errorHandle === false
      ) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
      // Check for errorHandle config
      if (error.response.status === 403) {
        return new Promise((resolve, reject) => {
          snackbar.error("Insufficient rights");
          reject(error);
        });
      }

      // If has response show the error
      if (error.response) {
        return new Promise((resolve, reject) => {
          snackbar.error();
          reject(error);
        });
      }
    }
  }
);

export default {
  //AUTH
  getUser: function() {
    return axios.get("/users/me/");
  },

  getCrmUser: function() {
    return axios.get("crm/users/me/");
  },
  //END OF AUTH

  //PARTNERS
  getDealerList: function(params) {
    let addedString = "";
    if (params) {
      addedString = "?" + new URLSearchParams(params).toString();
    }
    return axios.get("/crm/charter/partners/" + addedString);
  },

  deleteDealer: function(partnerId) {
    let path = "/crm/dealers/" + partnerId + "/";
    return axios.delete(path);
  },

  updateDealer: function(partnerId, data) {
    let path = "/crm/dealers/" + partnerId + "/";
    return axios.put(path, data);
  },

  createDealer: function(data) {
    let path = "/crm/dealers/";
    return axios.post(path, data);
  },

  getDealer: function(partnerId) {
    let path = "/crm/dealers/" + partnerId + "/";
    return axios.get(path);
  },

  inviteDealerUser: (partnerUuid, email) => {
    let path = "/crm/dealers/" + partnerUuid + "/invite-user/";
    return axios.post(path, email);
  },

  rejectInviteDealerUser: (uuid) => {
    let path = "/crm/invites/" + uuid + "/reject/";
    return axios.post(path);
  },

  deleteUserFromDealers: (uuid, params) => {
    let path = "/crm/dealers/" + uuid + "/remove-user/";
    return axios.post(path, params);
  },

  transferBoats: (uuid, params) => {
    let path = "/crm/charter/partners/" + uuid + "/transfer-boats/";
    return axios.post(path, params);
  },
  setAdmin: (partnerUUID, userUUID) => {
    let path =
      "/crm/partners/" + partnerUUID + "/members/" + userUUID + "/set-admin/";
    return axios.post(path);
  },
  setCommunications: (partnerUUID, userUUID) => {
    let path =
      "/crm/partners/" +
      partnerUUID +
      "/members/" +
      userUUID +
      "/set-communications/";
    return axios.post(path);
  },

  setUserLabels: (partnerUUID, userUUID, labels) => {
    let path =
      "/crm/partners/" + partnerUUID + "/members/" + userUUID + "/set-labels/";
    return axios.post(path, { labels: labels });
  },

  getCrmPartnerBoats(boatUuid) {
    return axios.get("/crm/partners/" + boatUuid + "/boats/");
  },
  //END OF PARTNERS

  //MESSAGES
  getLastMessageThread: () => {
    let path = "/crm/tickets/last/";
    return axios.get(path);
  },

  getSharedTask: function(taskId) {
    let path = "/crm/shared-tasks/" + taskId + "/";
    return axios.get(path);
  },

  getTicketList: function(params) {
    let addedString = "";
    if (params) {
      addedString = "?" + new URLSearchParams(params).toString();
    }
    return axios.get("/crm/tickets/" + addedString);
  },
  getTicketNextPage: function(path) {
    return axios.get(path);
  },
  getTicket: function(ticketId, error = true) {
    return axios.get("/crm/tickets/" + ticketId + "/", {
      errorHandle: error,
    });
  },
  closeTicket: function(ticketId) {
    return axios.post("/crm/tickets/" + ticketId + "/close/");
  },
  reopenTicket: function(ticketId) {
    return axios.post("/crm/tickets/" + ticketId + "/reopen/");
  },
  assigneeAvailableUsers: function(ticketId) {
    return axios.get("/crm/tickets/" + ticketId + "/available-users/");
  },
  assignUsers: function(ticketId, data) {
    return axios.post("/crm/tickets/" + ticketId + "/assign-user/", data);
  },
  createTicketNote: function(ticketId, data) {
    return axios.post("/crm/tickets/" + ticketId + "/messages/", data);
  },
  updateTicketNote: function(ticketId, messagesId, data) {
    return axios.patch(
      "/crm/tickets/" + ticketId + "/messages/" + messagesId + "/",
      data
    );
  },
  deleteTicketNote: function(ticketId, messagesId, data) {
    return axios.delete(
      "/crm/tickets/" + ticketId + "/messages/" + messagesId + "/delete",
      data
    );
  },

  uploadaxiosLogAttachment: function(axiosLogId, data) {
    let path = "/crm/axios-logs/" + axiosLogId + "/post-resolving-attachment/";
    return axios.post(path, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  },
  resolveaxiosLog: function(axiosLogId, data) {
    return axios.post(
      "/crm/axios-logs/" + axiosLogId + "/post-resolving-note/",
      data
    );
  },
  updateResolvedaxiosLog: function(axiosLogId, data) {
    return axios.put(
      "/crm/axios-logs/" + axiosLogId + "/post-resolving-note/",
      data
    );
  },
  createTaskFromMessage: function(ticketId, messageId) {
    return axios.post(
      "crm/tickets/" + ticketId + "/messages/" + messageId + "/create-task/"
    );
  },

  uploadMessageAttachment: function(data) {
    return axios.post("/upload-file/", data);
  },

  uploadMessageAttachmentFile: function(path, data) {
    return axios.post(path, data, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "",
      },
    });
  },
  //END OF MESSAGES

  //BOOKINGS
  // getBookingList: function(params) {
  //   let addedString = "";
  //   if (params) {
  //     addedString = "?" + new URLSearchParams(params).toString();
  //   }
  //   return axios.get("/crm/charter/bookings/" + addedString);
  // },

  getBooking: function(bookingId) {
    let path = "/crm/charter/bookings/" + bookingId + "/";
    return axios.get(path);
  },

  removeBooking: function(bookingId) {
    let path = "/crm/charter/bookings/" + bookingId + "/";
    return axios.delete(path);
  },

  getBookingBoats: function(params) {
    let addedString = "";
    if (params) {
      addedString = "?" + new URLSearchParams(params).toString();
    }
    let path = "/crm/charter/bookings/boats/" + addedString;
    return axios.get(path);
  },

  getBookingChecklists: function(params) {
    let addedString = "";
    if (params) {
      addedString = "?" + new URLSearchParams(params).toString();
    }
    let path = "/crm/charter/bookings/checklists/" + addedString;
    return axios.get(path);
  },

  createBooking: function(data) {
    let path = "/crm/charter/bookings/";
    return axios.post(path, data);
  },
  updateBooking: function(bookingId, data) {
    let path = "/crm/charter/bookings/" + bookingId + "/";
    return axios.put(path, data);
  },

  deleteBooking: function(bookingId, data) {
    let path = "/crm/charter/bookings/" + bookingId + "/";
    return axios.delete(path);
  },
  //END OF BOOKINGS
  //TASKS
  getTaskList: function(params) {
    let addedString = "";
    if (params) {
      addedString = "?" + new URLSearchParams(params).toString();
    }
    return axios.get("/crm/tasks/" + addedString);
  },

  getTask: function(taskId) {
    let path = "/crm/tasks/" + taskId + "/";
    return axios.get(path);
  },

  saveSubtask: function(taskId, data) {
    let path = "/crm/tasks/" + taskId + "/";

    return axios.patch(path, data);
  },

  saveSharedSubtask: function(taskId, data) {
    let path = "/crm/shared-tasks/" + taskId + "/";
    return axios.patch(path, data);
  },

  createTask: function(data) {
    let path = "/crm/tasks/";
    return axios.post(path, data);
  },
  updateTask: function(taskId, data) {
    let path = "/crm/tasks/" + taskId + "/";
    return axios.put(path, data);
  },

  reopenTask: function(taskId, data) {
    let path = "/crm/tasks/" + taskId + "/";
    return axios.patch(path, data);
  },

  finishTask: function(taskId) {
    let path = "/crm/tasks/" + taskId + "/finish/";
    return axios.post(path);
  },

  deleteTask: function(taskId, data) {
    let path = "/crm/tasks/" + taskId + "/";
    return axios.delete(path);
  },

  taskAssigneeAutocomplete: function(taskId, params) {
    let addedString = "";
    if (params) {
      addedString = "?" + new URLSearchParams(params).toString();
    }
    return axios.get("/crm/partners/" + taskId + "/members/");
  },

  taskAttachment: function(taskId, data) {
    let path = "/crm/tasks/" + taskId + "/post-attachment/";
    return axios.post(path, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  },

  sharedTaskAttachment: function(taskId, data) {
    let path = "/crm/shared-tasks/" + taskId + "/post-attachment/";
    return axios.post(path, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  },

  dealersAutocomplete: function(params, error = true) {
    let addedString = "";
    if (params) {
      addedString = "?" + new URLSearchParams(params).toString();
    }
    return axios.get("crm/dealers/" + addedString + "&autocomplete=true", {
      errorHandle: error,
    });
  },

  boatsAutocomplete: function(params) {
    let addedString = "";
    if (params) {
      addedString = "?" + new URLSearchParams(params).toString();
    }
    return axios.get("crm/builder/boats/" + addedString);
  },

  //TEMPLATES
  getTemplateList: function(params) {
    let addedString = "";
    if (params) {
      addedString = "?" + new URLSearchParams(params).toString();
    }
    return axios.get("/crm/tasks/templates/" + addedString);
  },

  createTemplate: function(data) {
    let path = "/crm/tasks/templates/";
    return axios.post(path, data);
  },

  updateTemplate: function(templateId, data) {
    let path = "/crm/tasks/templates/" + templateId + "/";
    return axios.put(path, data);
  },

  getTemlate: function(templateId) {
    let path = "/crm/tasks/templates/" + templateId + "/";
    return axios.get(path);
  },

  deleteTemplate: function(templateId) {
    let path = "/crm/tasks/templates/" + templateId + "/";
    return axios.delete(path);
  },

  templateAttachment: function(templateId, data, params) {
    let addedString = "";
    if (params) {
      addedString = "?" + new URLSearchParams(params).toString();
    }
    let path =
      "/crm/tasks/templates/" + templateId + "/post-attachment/" + addedString;
    return axios.post(path, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  },

  sharedTemplateAttachment: function(templateUuid, checklistUuid, data) {
    let path =
      "crm/tasks/templates/" +
      templateUuid +
      "/post-attachment/?checklist_uuid=" +
      checklistUuid;
    return axios.post(path, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  },

  duplicateTemplate: function(templateId) {
    let path = "/crm/tasks/templates/" + templateId + "/duplicate/";
    return axios.post(path);
  },

  //ATTACHMENTS
  uploadAttachments(data) {
    return axios.post("/direct-file-upload/", data);
  },

  uploadAttachmentFile: function(path, data) {
    return axios.post(path, data, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "",
      },
    });
  },

  setTicket: (data) => {
    var path = "/crm/tickets/";
    return axios.post(path, data);
  },

  // DASHBOARDS
  getDashboardList: function() {
    return axios.get("/crm/charter/dashboards/");
  },

  getDashboard: function(dashId, params) {
    let addedString = "";
    if (params) {
      addedString = "?" + new URLSearchParams(params).toString();
    }
    return axios.get("/crm/charter/dashboards/" + dashId + "/" + addedString);
  },

  //AUTOMATIONS
  getAutomationsList() {
    return new Promise((resolve, reject) => {
      const data = [
        {
          uuid: "5ac867a8825b47ad99824cd47cf544a7",
          trigger: "Before departure",
          type_of_automation: "Onboarding",
          target_audience: "Guest",
          title: "Guest Onboarding",
          checklists: [
            {
              uuid: "279f6224eb284819951d5cfda0eeec70",
              name: "Diver inspection",
              subtasks: [
                {
                  uuid: "7d0d45cdfc8c49149237d990f0b432f3",
                  description: "check keel",
                },
                {
                  uuid: "b7526382d0cd43168d864633cc247fd3",
                  description: "check rudder",
                },
                {
                  uuid: "0098c204df974d398b9d00ee8861eaa2",
                  description: "clean triducer",
                },
              ],
              checklist_type: null,
              crm_boat: {
                uuid: "83452be14f4e4dd7a8aaf6a595d7a04a",
                model: {
                  uuid: "230d66f60b7f4953a8535bb5338fc42a",
                  brand_name: "Hanse",
                  model: "41",
                },
                boat_name: "Biopsea",
                pretty_name: "41",
                device_serial: "Test Device 7855",
                slot_number: null,
                ce_number: null,
              },
              attachments: [],
            },
          ],
          crm_group: {
            uuid: "407193584eeb422a988c8750b1c16550",
            name: "Split Marina",
            is_supergroup: false,
            is_range: false,
            parent_uuid: null,
          },
        },
      ];
      resolve(data);
    });
  },
  getAutomation() {
    return new Promise((resolve, reject) => {
      const data = {
        uuid: "5ac867a8825b47ad99824cd47cf544a7",
        checklists: [
          {
            uuid: "279f6224eb284819951d5cfda0eeec70",
            name: "Diver inspection",
            subtasks: [
              {
                uuid: "7d0d45cdfc8c49149237d990f0b432f3",
                description: "check keel",
              },
              {
                uuid: "b7526382d0cd43168d864633cc247fd3",
                description: "check rudder",
              },
              {
                uuid: "0098c204df974d398b9d00ee8861eaa2",
                description: "clean triducer",
              },
            ],
            checklist_type: null,
            crm_boat: {
              uuid: "83452be14f4e4dd7a8aaf6a595d7a04a",
              model: {
                uuid: "230d66f60b7f4953a8535bb5338fc42a",
                brand_name: "Hanse",
                model: "41",
              },
              boat_name: "Biopsea",
              pretty_name: "41",
              device_serial: "Test Device 7855",
              slot_number: null,
              ce_number: null,
            },
            attachments: [],
          },
        ],
        crm_group: {
          uuid: "407193584eeb422a988c8750b1c16550",
          name: "Marina Trogir",
          is_supergroup: false,
          is_range: false,
          parent_uuid: null,
        },
      };
      resolve(data);
    });
  },
  updateAutomation() {
    return new Promise((resolve, reject) => {
      const data = {};
      resolve(data);
    });
  },
  createAutomation() {
    return new Promise((resolve, reject) => {
      const data = {};
      resolve(data);
    });
  },

  //MAINTENANCE

  getMaintenanceList() {
    return new Promise((resolve, reject) => {
      const data = {
        count: 8,
        next: null,
        previous: null,
        results: [
          {
            uuid: "d1487a1637c5484a9fe85ace06d24011",
            crm_boat_item: {
              uuid: "cbd8812dd43c4fbe9b1d3280bd17ce12",
              serial_number: "",
              warranty_months: null,
              warranty_start_date: null,
              extended_warranty_months: null,
              created: "2022-05-24T09:31:30.600739Z",
              crm_item: {
                uuid: "2d582781baed4ae0a317a2576da56c11",
                name: "MainSail",
                vendor: {
                  uuid: "84a141d36d3f425f93ff762a386a3968",
                  name: "Quantum",
                },
                sku: null,
                description: null,
                categories: ["sails"],
                formatted_name: "Quantum MainSail",
              },
            },
            crm_service: {
              uuid: "edb077907ddf4398b40e357ebc740711",
              short_task: "Inspection",
              long_task: "",
              intervals: [
                {
                  uuid: "0e727a142e1c480c97d9bd7b76fa9211",
                  periodic: true,
                  source: "ITEM_AGE_MONTHS",
                  value: 12,
                },
              ],
              subtasks: [
                {
                  uuid: "205d734623d94b4f97a735484e00b56a",
                  description:
                    "Obtain and evaluate photos of sail in flying position.",
                },
                {
                  uuid: "1b1c3d66c521464cb422998b9e332c7b",
                  description:
                    "Look for chafe marks on full-length batten pockets.",
                },
                {
                  uuid: "255e0b48bbd848da832073e26822b278",
                  description:
                    "For in-boom furling mainsails check inboard ends of all batten pockets for chafe and fraying.",
                },
                {
                  uuid: "fe823cc110394d3ca732a9da9d1b123d",
                  description:
                    "For in-mast furling mainsails check inner edge of clew UV cover for UV damage or excess dirt.",
                },
                {
                  uuid: "e4c95717d88f47939c20a3db828ba1cd",
                  description:
                    "Check hand stitching on luff slides for chafe/fraying.",
                },
                {
                  uuid: "be58f2abfff94ecea6c30374cb99fbe8",
                  description:
                    "Bolt ropes: Check at head/batten pocket/tack for chafe.",
                },
                {
                  uuid: "2dd39793744640f6b0612cf1a2052612",
                  description:
                    "Luff/foot attachments with pressed grommets: Check for wrinkles originating at the grommet and extending into body of sail.",
                },
                {
                  uuid: "36d08522d9e84cddaf51102b0519488c",
                  description:
                    "Check batten pockets for wear. Ensure inboard end reinforcements for batten pockets are robust and batten tensioning systems are functioning correctly.",
                },
                {
                  uuid: "e1698212e64e420fb6244cbc1e827a81",
                  description: "Check battens for cracking/splintering.",
                },
                {
                  uuid: "40bf047787b04f9f8f0d15641a18705e",
                  description:
                    "Examine leech of sail for indications of excess dirt or UV damage.",
                },
              ],
            },
            requested: null,
            engine_seconds_requested: null,
            crm_boat: {
              uuid: "5dc307027e2e4e5eae35fcbf7a6f4a92",
              model: {
                uuid: "8e7e295359d249e1bcb3e46c278be46b",
                brand_name: "Tipsy",
                model: "",
              },
              boat_name: null,
              pretty_name: "Tipsy",
              device_serial: "3321100051",
              slot_number: 51,
              ce_number: "2e2e2",
            },
            crm_partner: null,
            responsive_dealer: null,
            crm_internal_task: {
              uuid: "4f4fc4d6-d617-4ad5-967e-721cfd6ab7bd",
              completed: null,
              status: "IN_PROGRESS",
              assignee_status: "CREATED",
            },
            should_be_done_before: "2022-05-18T09:43:30Z",
            should_be_engine_seconds_before: null,
            request_trigger: null,
            service_status: "overdue",
            completed: null,
            engine_seconds_completed: null,
            engine_seconds: null,
            requires_attention: false,
            user_notified: null,
          },
          {
            uuid: "8506d37301e642c3a98fa298af165578",
            crm_boat_item: {
              uuid: "160225ce5b6a4157b0f263766bb6e671",
              serial_number: "",
              warranty_months: null,
              warranty_start_date: null,
              extended_warranty_months: null,
              created: "2022-05-24T09:32:19.357266Z",
              crm_item: {
                uuid: "e5ea4a3bda0f4b6a8a0bc9e759403bec",
                name: "Battery",
                vendor: {
                  uuid: "5670dd10a7eb46eaac506120a00e2496",
                  name: "Tado Bra",
                },
                sku: null,
                description: null,
                categories: ["electrical systems"],
                formatted_name: "Tado Bra Battery",
              },
            },
            crm_service: {
              uuid: "2f72ef014d9c49cc8a757db356b2afbd",
              short_task: "Deep cycle",
              long_task: "",
              intervals: [
                {
                  uuid: "bf9cb8117d0d48eb9ddea0c7d61e8d8a",
                  periodic: true,
                  source: "MILEAGE",
                  value: 100,
                },
                {
                  uuid: "ea0a3ada5c8b437bb1593b6f1167a100",
                  periodic: true,
                  source: "ITEM_AGE_MONTHS",
                  value: 100,
                },
                {
                  uuid: "faf26ef7281a471eb4164bf20adcc2d9",
                  periodic: true,
                  source: "ENGINE_HOURS",
                  value: 100,
                },
                {
                  uuid: "beca2dc293a445dc9bce9091d0de6e84",
                  periodic: false,
                  source: "MANUAL",
                  value: 100,
                },
              ],
              subtasks: [
                {
                  uuid: "c97d5743f86c4fb18bf28e07e085b33d",
                  description: "ars",
                },
                {
                  uuid: "b046e70e10df45889ac8c012952df1c7",
                  description: "tar",
                },
                {
                  uuid: "a804c4170a784a59b3c1bbf043550d7c",
                  description: "st",
                },
                {
                  uuid: "0f00c2be8fe04921b1dd643e99dc9cc4",
                  description: "arst",
                },
              ],
            },
            requested: null,
            engine_seconds_requested: null,
            crm_boat: {
              uuid: "5dc307027e2e4e5eae35fcbf7a6f4a92",
              model: {
                uuid: "8e7e295359d249e1bcb3e46c278be46b",
                brand_name: "Tipsy",
                model: "",
              },
              boat_name: null,
              pretty_name: "Tipsy",
              device_serial: "3321100051",
              slot_number: 51,
              ce_number: "2e2e2",
            },
            crm_partner: null,
            responsive_dealer: null,
            crm_internal_task: null,
            should_be_done_before: "2022-06-14T09:43:30Z",
            should_be_engine_seconds_before: null,
            request_trigger: null,
            service_status: "upcoming",
            completed: null,
            engine_seconds_completed: null,
            engine_seconds: null,
            requires_attention: false,
            user_notified: null,
          },
          {
            uuid: "d1487a1637c5484a9fe85ace06d24041",
            crm_boat_item: {
              uuid: "cbd8812dd43c4fbe9b1d3280bd17ce80",
              serial_number: "",
              warranty_months: null,
              warranty_start_date: null,
              extended_warranty_months: null,
              created: "2022-05-24T09:31:30.600739Z",
              crm_item: {
                uuid: "2d582781baed4ae0a317a2576da56cfa",
                name: "Engine",
                vendor: {
                  uuid: "84a141d36d3f425f93ff762a386a3968",
                  name: "Quantum",
                },
                sku: null,
                description: null,
                categories: ["sails"],
                formatted_name: "Quantum MainSail",
              },
            },
            crm_service: {
              uuid: "edb077907ddf4398b40e357ebc7407b9",
              short_task: "100 hours",
              long_task: "",
              intervals: [
                {
                  uuid: "0e727a142e1c480c97d9bd7b76fa9275",
                  periodic: true,
                  source: "ENGINE_HOURS",
                  value: 20,
                },
              ],
              subtasks: [
                {
                  uuid: "205d734623d94b4f97a735484e00b56a",
                  description:
                    "Obtain and evaluate photos of sail in flying position.",
                },
                {
                  uuid: "1b1c3d66c521464cb422998b9e332c7b",
                  description:
                    "Look for chafe marks on full-length batten pockets.",
                },
                {
                  uuid: "255e0b48bbd848da832073e26822b278",
                  description:
                    "For in-boom furling mainsails check inboard ends of all batten pockets for chafe and fraying.",
                },
                {
                  uuid: "fe823cc110394d3ca732a9da9d1b123d",
                  description:
                    "For in-mast furling mainsails check inner edge of clew UV cover for UV damage or excess dirt.",
                },
                {
                  uuid: "e4c95717d88f47939c20a3db828ba1cd",
                  description:
                    "Check hand stitching on luff slides for chafe/fraying.",
                },
                {
                  uuid: "be58f2abfff94ecea6c30374cb99fbe8",
                  description:
                    "Bolt ropes: Check at head/batten pocket/tack for chafe.",
                },
                {
                  uuid: "2dd39793744640f6b0612cf1a2052612",
                  description:
                    "Luff/foot attachments with pressed grommets: Check for wrinkles originating at the grommet and extending into body of sail.",
                },
                {
                  uuid: "36d08522d9e84cddaf51102b0519488c",
                  description:
                    "Check batten pockets for wear. Ensure inboard end reinforcements for batten pockets are robust and batten tensioning systems are functioning correctly.",
                },
                {
                  uuid: "e1698212e64e420fb6244cbc1e827a81",
                  description: "Check battens for cracking/splintering.",
                },
                {
                  uuid: "40bf047787b04f9f8f0d15641a18705e",
                  description:
                    "Examine leech of sail for indications of excess dirt or UV damage.",
                },
              ],
            },
            requested: null,
            engine_seconds_requested: null,
            crm_boat: {
              uuid: "5dc307027e2e4e5eae35fcbf7a6f4a92",
              model: {
                uuid: "8e7e295359d249e1bcb3e46c278be46b",
                brand_name: "Tipsy",
                model: "",
              },
              boat_name: null,
              pretty_name: "Tipsy",
              device_serial: "3321100051",
              slot_number: 51,
              ce_number: "2e2e2",
            },
            crm_partner: null,
            responsive_dealer: null,
            crm_internal_task: null,
            should_be_done_before: "2022-09-11T12:00:20Z",
            should_be_engine_seconds_before: 3600 * 60 * 1.67,
            request_trigger: null,
            service_status: "upcoming",
            completed: null,
            engine_seconds_completed: null,
            engine_seconds: 3600 * 60 * 1.4,
            requires_attention: false,
            user_notified: null,
          },

          {
            uuid: "e35da39c90ff46a4909a9a8c89f5c602",
            crm_boat_item: {
              uuid: "e83c183a003d4097ae82d63b98232f22",
              serial_number: "",
              warranty_months: null,
              warranty_start_date: null,
              extended_warranty_months: null,
              created: "2021-04-06T11:40:55.726311Z",
              crm_item: {
                uuid: "2d39dc72b34546919df10f3a4e793a26",
                name: "Sparay hood",
                vendor: {
                  uuid: "a4f78db2fc234405910ceb0b27f5c972",
                  name: "Generic",
                },
                sku: "FAKE003",
                description: null,
                categories: ["sails"],
                formatted_name: "Generic Gennaker",
              },
            },
            crm_service: {
              uuid: "126bc39ce74f4c77b8c7752d159d455c",
              short_task: "Replacement",
              long_task: "",
              intervals: [
                {
                  uuid: "10a15386273848beb9affc7be1800b26",
                  periodic: true,
                  source: "MANUAL",
                  value: 0,
                },
              ],
              subtasks: [
                {
                  uuid: "3bf9d9cace074a43a3c833888123e506",
                  description:
                    "Look for chafe marks on full-length batten pockets.",
                },
                {
                  uuid: "f23084591777486d9705b52a8798ee41",
                  description:
                    "For in-boom furling mainsails check inboard ends of all batten pockets for chafe and fraying.",
                },
                {
                  uuid: "b5e7de682c224420b7e0e70b62f502a3",
                  description:
                    "For in-mast furling mainsails check inner edge of clew UV cover for UV damage or excess dirt.",
                },
                {
                  uuid: "864532c74fc1431b94be52516f70e11e",
                  description:
                    "Check hand stitching on luff slides for chafe/fraying.",
                },
                {
                  uuid: "d07ed983867c4204b2d79a57e66baccf",
                  description:
                    "Bolt ropes: Check at head/batten pocket/tack for chafe.",
                },
                {
                  uuid: "71f1bfa8936f47878d15fb42345ad263",
                  description:
                    "Luff/foot attachments with pressed grommets: Check for wrinkles originating at the grommet and extending into body of sail.",
                },
                {
                  uuid: "0c56059169be4ed2941560688228bd5d",
                  description:
                    "Check batten pockets for wear. Ensure inboard end reinforcements for batten pockets are robust and batten tensioning systems are functioning correctly.",
                },
                {
                  uuid: "f32a804327f1492b83bc71d76a64ae1c",
                  description: "33",
                },
                {
                  uuid: "7a62ea22ffc648559041f37bde105568",
                  description: "33",
                },
              ],
            },
            requested: null,
            engine_seconds_requested: null,
            crm_boat: {
              uuid: "fa78c92d71d44a668950fb61d627aaa5",
              model: {
                uuid: "7ef06ecbf0c949308f946f296566e42b",
                brand_name: "Tipsy",
                model: "",
              },
              boat_name: null,
              pretty_name: "SargoX 123 #777",
              device_serial: "SIQ3",
              slot_number: 51,
              ce_number: "PI7778",
            },
            crm_partner: {
              uuid: "dd71913c5512447d8cc1333c3839e7d7",
              name: "Hassan Haudi 122",
              domains: [],
              address: "Bospor 131",
              city: "Istanbul",
              country: "AL",
              location: {
                type: "Point",
                coordinates: [29.0121584, 41.0430572],
              },
              phone: null,
              email: "klemen+128@sentinelmarine.net",
              website: null,
              crm_group: {
                uuid: "71476dc49f6d47f7ab51645a8f0a785f",
                name: "Haudi AG",
                is_supergroup: true,
                is_range: false,
                parent_uuid: null,
              },
            },
            responsive_dealer: {
              uuid: "7182d359c7ed4520b7c41aac81297ce7",
              name: "Prov3",
              domains: [],
              address: "arstarst",
              city: "arst",
              country: "AD",
              location: {
                type: "Point",
                coordinates: [-97.2632026, 37.6960136],
              },
              phone: "",
              email: "prov3@pedro.si",
              website: "",
              crm_group: {
                uuid: "71476dc49f6d47f7ab51645a8f0a785f",
                name: "Haudi AG",
                is_supergroup: true,
                is_range: false,
                parent_uuid: null,
              },
            },
            crm_internal_task: {
              uuid: "4f4fc4d6-d617-4ad5-967e-721cfd6ab7bd",
              completed: null,
              status: "IN_PROGRESS",
              assignee_status: "CREATED",
            },
            should_be_done_before: "2022-04-11T12:00:20Z",
            should_be_engine_seconds_before: null,
            request_trigger: null,
            service_status: "complete",
            completed: "2022-3-29T00:00:00Z",
            engine_seconds_completed: null,
            engine_seconds: null,
            requires_attention: false,
            user_notified: "2021-12-29T16:11:40.111752Z",
          },
        ],
      };
      resolve(data);
    });
  },

  //DOCUMENTS
  getDocumentList() {
    return new Promise((resolve, reject) => {
      const data = {
        count: 1,
        next: null,
        previous: null,
        results: [
          {
            uuid: "c4a2f3d25df7401da5832d88103ae577",
            file_resource: {
              title: "",
              thumbnail_link: null,
              type: "Link",
              categories: ["Onboarding", "Guest"],
              languages: ["en"],
              link: "https://en.wikipedia.org/wiki/Main_Page",
              file: null,
              created: "2022-03-17T11:39:06.444539Z",
              uuid: "c28eaf68c2b048d69338b74f200b20e2",
            },
            file_resources: [
              {
                title: "",
                thumbnail_link: null,
                type: "Link",
                categories: ["data", "engine", "brisalec"],
                languages: ["en"],
                link: "https://en.wikipedia.org/wiki/Main_Page",
                file: null,
                created: "2022-03-17T11:39:06.444539Z",
                uuid: "c28eaf68c2b048d69338b74f200b20e2",
              },
            ],
            crm_group: {
              uuid: "71476dc49f6d47f7ab51645a8f0a785f",
              name: "Split Marina",
              is_supergroup: true,
              is_range: false,
              parent_uuid: null,
            },
            is_boat_specific: false,
            is_editable: true,
            is_readable: true,
            is_removable: true,
            created: "2022-03-17T11:39:06.460445Z",
            title: "General TAC",
            categories: ["data", "engine", "brisalec"],
          },
          {
            uuid: "c4a2f3d25df7401da5832d88103ae577",
            file_resource: {
              title: "",
              thumbnail_link: null,
              type: "File",
              categories: ["Onboarding", "Guest"],
              languages: ["en"],
              link: "https://en.wikipedia.org/wiki/Main_Page",
              file: null,
              created: "2022-03-17T11:39:06.444539Z",
              uuid: "c28eaf68c2b048d69338b74f200b20e2",
            },
            file_resources: [
              {
                title: "",
                thumbnail_link: null,
                type: "link",
                categories: ["data", "engine", "brisalec"],
                languages: ["en"],
                link: "https://en.wikipedia.org/wiki/Main_Page",
                file: null,
                created: "2022-03-17T11:39:06.444539Z",
                uuid: "c28eaf68c2b048d69338b74f200b20e2",
              },
            ],
            crm_group: {
              uuid: "71476dc49f6d47f7ab51645a8f0a785f",
              name: "Split Marina",
              is_supergroup: true,
              is_range: false,
              parent_uuid: null,
            },
            is_boat_specific: false,
            is_editable: true,
            is_readable: true,
            is_removable: true,
            created: "2022-03-17T11:39:06.460445Z",
            title: "Oceanis 44 Quick tour",
            categories: ["data", "engine", "brisalec"],
          },
        ],
      };
      resolve(data);
    });
  },
};
