import {
  CAN_USER_WRITE_PARTNERS,
  COMPUTE_CAN_USER_WRITE_PARTNERS_PERMISSION,
  CRM_GROUPS_WITH_WRITE_PERMISSION,
  COMPUTE_CRM_GROUPS_WITH_WRITE_PERMISSION,
  HAS_HIDDEN_BOOKINGS,
  COMPUTE_HAS_HIDDEN_BOOKINGS,
} from "@/store/actions/permissions";
import { USER_CRM_REQUEST } from "@/store/actions/user";

export const getDefaultState = () => ({
  canUserWritePartners: false,
  crmGroupsWithWritePermission: [],
  hasHiddenBookings: false,
});
const state = getDefaultState();

const getters = {
  getCanUserWritePartners: (state) => state.canUserWritePartners,
  getCrmGroupsWithWritePermission: (state) =>
    state.crmGroupsWithWritePermission,
  getHasHiddenBookings: (state) => state.hasHiddenBookings,
};

const actions = {
  [COMPUTE_HAS_HIDDEN_BOOKINGS]: ({ commit, getters, dispatch }, data) => {
    let compute = (getMe) => {
      if (Object.keys(getMe.features).length === 0) {
        return false;
      }

      let hasHiddenBookings = false;
      try {
        hasHiddenBookings = getMe.features.has_hidden_bookings;
      } catch (error) {
        console.error(error);
        hasHiddenBookings = false;
      }

      return hasHiddenBookings;
    };

    if (Object.keys(getters.getMe).length === 0) {
      dispatch(USER_CRM_REQUEST).then((resp) => {
        let result = compute(resp);
        commit("HAS_HIDDEN_BOOKINGS", { result });
      });
    } else {
      let result = compute(getters.getMe);
      commit("HAS_HIDDEN_BOOKINGS", { result });
    }
  },
  [COMPUTE_CAN_USER_WRITE_PARTNERS_PERMISSION]: (
    { commit, getters, dispatch },
    data
  ) => {
    let compute = (getMe) => {
      if (getMe.crm_groups.length === 0) {
        return false;
      }

      return getMe.crm_groups.some((group) => {
        if (group.roles.length === 0) {
          return false;
        }
        return group.roles.some((role) => {
          return role.dealers_permission === "WRITE";
        });
      });
    };

    if (Object.keys(getters.getMe).length === 0) {
      dispatch(USER_CRM_REQUEST).then((resp) => {
        let result = compute(resp);
        commit("CAN_USER_WRITE_PARTNERS", { result });
      });
    } else {
      let result = compute(getters.getMe);
      commit("CAN_USER_WRITE_PARTNERS", { result });
    }
  },
  [COMPUTE_CRM_GROUPS_WITH_WRITE_PERMISSION]: (
    { commit, getters, dispatch },
    data
  ) => {
    let compute = (getMe) => {
      if (getMe.crm_groups.length === 0) {
        return 0;
      }

      return getMe.crm_groups.filter((group) => {
        if (group.roles.length === 0) {
          return false;
        }
        return group.roles.filter((role) => {
          return role.dealers_permission === "WRITE";
        });
      });
    };

    if (Object.keys(getters.getMe).length === 0) {
      dispatch(USER_CRM_REQUEST).then((resp) => {
        let result = compute(resp);
        commit("CRM_GROUPS_WITH_WRITE_PERMISSION", { result });
      });
    } else {
      let result = compute(getters.getMe);
      commit("CRM_GROUPS_WITH_WRITE_PERMISSION", { result });
    }
  },
};

const mutations = {
  [CAN_USER_WRITE_PARTNERS]: (state, value) => {
    state.canUserWritePartners = value.result;
  },
  [CRM_GROUPS_WITH_WRITE_PERMISSION]: (state, value) => {
    state.crmGroupsWithWritePermission = value.result;
  },
  [HAS_HIDDEN_BOOKINGS]: (state, value) => {
    state.hasHiddenBookings = value.result;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
