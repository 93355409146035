<template>
  <div>
    <v-card>
      <router-link
        class="white--text navigation-a-underline--remove"
        :to="{ name: 'Messages' }"
      >
        <v-list-item link>
          <v-list-item-action>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Messages</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="white--text navigation-a-underline--remove"
        :to="{ name: 'Tasks' }"
      >
        <v-list-item link>
          <v-list-item-action>
            <v-icon>mdi-hammer</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Tasks</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="white--text navigation-a-underline--remove"
        :to="{ name: 'Partners' }"
        v-if="true"
      >
        <v-list-item link>
          <v-list-item-action>
            <v-icon>mdi-check-box-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Partners</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="white--text navigation-a-underline--remove"
        :to="{ name: 'dashboards' }"
        v-if="true"
      >
        <v-list-item link>
          <v-list-item-action>
            <v-icon>mdi-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Dashboards</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
