import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";
import {
  USER_CRM_REQUEST,
  USER_KNOWLEDGEBASE_REQUEST,
} from "@/store/actions/user";

//GENERAL
const NoPermission = () => import("@/views/general/NoPermission");

//PARTNERS
const Partners = () => import("@/views/partners/Partners");
const PartnersDetails = () => import("@/views/partners/PartnersDetails");
const PartnersAddEdit = () => import("@/views/partners/PartnersAddEdit");

//MESSAGES
const Messages = () => import("@/views/messages/Messages");
const MessageThread = () => import("@/views/messages/MessageThread");

//TASKS
const TasksMain = () => import("@/views/tasks/TasksMain");
const Tasks = () => import("@/views/tasks/Tasks");
const TasksAddEdit = () => import("@/views/tasks/TasksAddEdit");
const TasksDetails = () => import("@/views/tasks/TasksDetails");
const Templates = () => import("@/views/tasks/Templates");
const TemplatesAddEdit = () => import("@/views/tasks/TemplatesAddEdit");
const Bookings = () => import("@/views/tasks/Bookings");
const Automations = () => import("@/views/tasks/Automations");
const AutomationsAddEdit = () => import("@/views/tasks/AutomationsAddEdit");
const Maintenance = () => import("@/views/tasks/Maintenance");
const Documents = () => import("@/views/tasks/Documents");
const Inventory = () => import("@/views/tasks/Inventory");
const InventoryAddEdit = () => import("@/views/tasks/InventoryAddEdit");
const Dashboard = () => import("@/views/dashboards/Dashboard");
const Dashboards = () => import("@/views/dashboards/Dashboards");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/no-permission",
    name: "NoPermission",
    component: NoPermission,
  },
  {
    path: "/partners",
    name: "Partners",
    component: Partners,
  },
  {
    path: "/partners/:id/",
    name: "PartnersDetails",
    component: PartnersDetails,
  },
  {
    path: "/partners/:tab/new/",
    name: "PartnersNew",
    component: PartnersAddEdit,
  },
  {
    path: "/partners/:id/:tab/edit/",
    name: "PartnersEdit",
    component: PartnersAddEdit,
  },
  {
    name: "dashboards",
    path: "/dashboards",
    component: Dashboards,
    meta: { visible: "dashboards" },
  },
  {
    name: "Messages",
    path: "/messages",
    component: Messages,
    children: [
      {
        path: ":id",
        name: "MessagesThread",
        component: MessageThread,
      },
    ],
  },
  {
    path: "/tasks",
    name: "Task",
    component: TasksMain,
    children: [
      {
        path: "tasks",
        name: "Tasks",
        component: Tasks,
        meta: { visible: "tasks", menu: "Tasks" },
      },
      {
        path: "tasks/new",
        name: "TasksNew",
        component: TasksAddEdit,
        meta: { visible: "tasks", menu: "Tasks" },
      },
      {
        path: "tasks/:id/",
        name: "TasksDetails",
        component: TasksDetails,
        meta: { visible: "tasks", menu: "Tasks" },
      },
      {
        path: "tasks/:id/edit",
        name: "TasksEdit",
        component: TasksAddEdit,
        meta: { visible: "tasks", menu: "Tasks" },
      },
      {
        path: "templates",
        name: "TasksTemplate",
        component: Templates,
        meta: { visible: "tasks", menu: "Templates" },
      },
      {
        path: "templates/:id/edit",
        name: "TasksTemplatesEdit",
        component: TemplatesAddEdit,
        meta: { visible: "tasks", menu: "Templates" },
      },
      {
        path: "templates/new",
        name: "TasksTemplatesNew",
        component: TemplatesAddEdit,
        meta: { visible: "tasks", menu: "Templates" },
      },
      {
        path: "bookings",
        name: "Bookings",
        component: Bookings,
        meta: { visible: "tasks", menu: "Bookings" },
      },
      {
        path: "automations",
        name: "Automations",
        component: Automations,
        meta: { visible: "tasks", menu: "Automations" },
      },
      {
        path: "automations/:id/edit",
        name: "AutomationsEdit",
        component: AutomationsAddEdit,
        meta: { visible: "tasks", menu: "Automations" },
      },
      {
        path: "automations/new",
        name: "AutomationsNew",
        component: AutomationsAddEdit,
        meta: { visible: "tasks", menu: "Automations" },
      },
      {
        path: "maintenance",
        name: "Maintenance",
        component: Maintenance,
        meta: { visible: "tasks", menu: "Maintenance" },
      },
      {
        path: "documents",
        name: "Documents",
        component: Documents,
        meta: { visible: "tasks", menu: "Documents" },
      },
      {
        path: "inventory",
        name: "Inventory",
        component: Inventory,
        meta: { visible: "tasks", menu: "Inventory" },
      },
      {
        path: "inventory/:id/edit",
        name: "InventoryEdit",
        component: InventoryAddEdit,
        meta: { visible: "tasks", menu: "Inventory" },
      },
      {
        path: "inventory/new",
        name: "InventoryNew",
        component: InventoryAddEdit,
        meta: { visible: "tasks", menu: "Inventory" },
      },
    ],
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function checkBookingAvailable() {
  let has_hidden_bookings = false;
  try {
    has_hidden_bookings = store.getters.getMe.features.has_hidden_bookings;
  } catch (error) {
    has_hidden_bookings = false;
  }

  if (has_hidden_bookings) {
    return { name: "Tasks" };
  }

  return { name: "Bookings" };
}

router.beforeEach((to, from, next) => {
  if (Object.keys(store.getters.getMe).length === 0) {
    store.dispatch(USER_CRM_REQUEST).then(() => {
      if (to.name === "Task") {
        next(checkBookingAvailable());
        return;
      } else {
        next();
        return;
      }
    });
  } else {
    if (to.name === "Task") {
      next(checkBookingAvailable());
      return;
    } else {
      next();
      return;
    }
  }

  next();
});

export default router;
