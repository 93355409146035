import {
  GET_DASHBOARDS,
  DASHBOARDS_REQUEST,
  DASHBOARDS_SUCCESS,
  DASHBOARDS_ERROR,
  DASHBOARD_SELECT,
} from "@/store/actions/dashboards";
import api from "@/utils/api";
import Vue from "vue";

export const getDefaultState = () => ({
  dashboards: [],
  error: "",
  loading: false,
  fetched: false,
  selected: {},
});
const state = getDefaultState();

const getters = {
  getDashboardsLoading: (state) => state.loading,
  getDashboardsError: (state) => state.error,
  getDashboardsFetched: (state) => state.fetched,
  getDashboardsState: (state) => state,
  getSelectedDashboard: (state) => state.selected,
};

const actions = {
  [GET_DASHBOARDS]: ({ commit, dispatch }) => {
    commit(DASHBOARDS_REQUEST);
    return api
      .getDashboardList()
      .then((resp) => {
        commit(DASHBOARDS_SUCCESS, resp.data);

        if (resp.data.length > 0) {
          commit(DASHBOARD_SELECT, resp.data[0]);
        }

        return resp.data;
      })
      .catch((resp) => {
        commit(DASHBOARDS_ERROR);
        return resp;
      });
  },
};

const mutations = {
  [DASHBOARDS_REQUEST]: (state) => {
    state.loading = true;
    state.fetched = false;
    state.error = false;
  },
  [DASHBOARDS_SUCCESS]: (state, resp) => {
    state.loading = false;
    state.fetched = true;
    state.error = false;
    state.dashboards = resp;
  },
  [DASHBOARDS_ERROR]: (state) => {
    state.loading = false;
    state.fetched = true;
    state.error = true;
    state.dashboards = [];
  },
  [DASHBOARD_SELECT]: (state, dashboard) => {
    Vue.set(state, "selected", dashboard);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
