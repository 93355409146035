import {
  USER_REQUEST,
  USER_ERROR,
  USER_SUCCESS,
  USER_CRM_REQUEST,
  USER_CRM_SUCCESS,
  USER_CRM_ERROR,
  USER_SET_ACCESS_TOKEN,
} from "@/store/actions/user";
import api from "@/utils/api";
import Vue from "vue";
import router from "@/router";
import cookie from "@/utils/cookie";

export const getDefaultState = () => ({
  access_token: "",
  refresh_token: "",
  profile: {},
  user: {},
});
const state = getDefaultState();

const getters = {
  getProfile: (state) => state.profile,
  getMe: (state) => state.user,
};

const actions = {
  [USER_REQUEST]: ({ commit, dispatch }) => {
    return api
      .getUser()
      .then((resp) => {
        commit(USER_SUCCESS, resp.data.user);
      })
      .catch((resp) => {
        commit(USER_ERROR);
      });
  },
  [USER_CRM_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(USER_REQUEST);
      commit(USER_CRM_REQUEST);
      api
        .getCrmUser()
        .then((resp) => {
          commit(USER_CRM_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((resp) => {
          commit(USER_CRM_ERROR);
          reject();
        });
    });
  },
};

const mutations = {
  [USER_SET_ACCESS_TOKEN]: (state, resp) => {
    state.access_token = resp.token;
  },

  [USER_REQUEST]: (state) => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "profile", resp);
  },
  [USER_ERROR]: (state) => {
    state.status = "error";
    router.push({
      name: "NoPermission",
    });
  },
  [USER_CRM_REQUEST]: (state) => {
    state.status = "loading";
  },
  [USER_CRM_SUCCESS]: (state, resp) => {
    Vue.set(state, "user", resp);
  },
  [USER_CRM_ERROR]: (state) => {
    state.status = "error";
    router.push({
      name: "NoPermission",
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
